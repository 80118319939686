import { LogLevel } from '@azure/msal-browser'
const { REACT_APP_AUTHORITY, REACT_APP_CLIENT_ID, REACT_APP_REDIRECT_URL, REACT_APP_TENANT_ID } = process.env
export const msalConfig = {
    auth: {
        clientId: `${REACT_APP_CLIENT_ID}`,
        authority: `${REACT_APP_AUTHORITY}`,
        // redirectUri: `${REACT_APP_REDIRECT_URL}`,
        knownAuthorities: [`https://login.microsoftonline.com/${REACT_APP_TENANT_ID}`]
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
    },

    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    case LogLevel.Info:
                        // console.info(message)
                        return

                    case LogLevel.Verbose:
                        // console.debug(message)
                        return

                    case LogLevel.Warning:
                        console.warn(message)
                        return
                }
            }
        }
    }
}

export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}
