import client from './client'

const getAllChatflows = () => client.get('/chatflows')

const getAllChatflowsofUser = (userid) => client.get(`/chatflows/user/${userid}`)

const getSpecificChatflow = (id) => client.get(`/chatflows/${id}`)

const getSpecificChatflowFromPublicEndpoint = (id) => client.get(`/public-chatflows/${id}`)

const createNewChatflow = (body) => client.post(`/chatflows`, body)

const updateChatflow = (id, body) => client.put(`/chatflows/${id}`, body)

const deleteChatflow = (id) => client.delete(`/chatflows/${id}`)

const getIsChatflowStreaming = (id) => client.get(`/chatflows-streaming/${id}`)

const getAllChatflowsApproved = () => client.get('/chatflows/approved')

const saveFlowconfig = (body) => client.post(`/saveConfig`, body)

const getFlowconfig = (body) => client.post(`/getConfig`, body)

export default {
    getAllChatflows,
    getSpecificChatflow,
    getSpecificChatflowFromPublicEndpoint,
    createNewChatflow,
    updateChatflow,
    deleteChatflow,
    getIsChatflowStreaming,
    getAllChatflowsofUser,
    getAllChatflowsApproved,
    saveFlowconfig,
    getFlowconfig
}
