export const initialState = {
    compareData: null,
    flowData: null,
    costData: null,
    pricingData: null,
    notebooksData: null,
    availableNotebooks: [],
    costingParams: null
}

// ==============================|| COST COMPARE REDUCER ||============================== //

const costCompareReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setCompareData':
            return {
                ...state,
                compareData: action.compareData
            }
        case 'setFlowData':
            return {
                ...state,
                flowData: action.flowData
            }
        //this contains all the pricings of the chat models
        case 'setCostData':
            return {
                ...state,
                costData: action.costData
            }
        case 'setNotebooksData':
            return {
                ...state,
                notebooksData: action.notebooksData
            }
        case 'setAvailableNotebooks':
            return {
                ...state,
                availableNotebooks: action.availableNotebooks
            }

        case 'setCostingParams':
            return {
                ...state,
                costingParams: action.costingParams
            }

        //this contains all the pricings of the components in the flow. ---depricated state.
        case 'setPricingData':
            return {
                ...state,
                pricingData: action.pricingData
            }
        default:
            return state
    }
}

export default costCompareReducer
