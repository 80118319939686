import React from 'react'
import { PDFViewer, Document, Page, View, Text, StyleSheet, Image, Font } from '@react-pdf/renderer'
import Logo from 'assets/images/logo-light.jpg'
import asterisk from 'assets/images/asterisk.png'
import PropTypes from 'prop-types'

//fonts imports for react pdf.
// import PoppinsRegular from 'assets/fonts/Poppins-Regular.ttf'
// import PoppinsBold from 'assets/fonts/Poppins-Bold.ttf'
// import PoppinsExtraBold from 'assets/fonts/Poppins-ExtraBold.ttf'
// import PoppinsSemiBold from 'assets/fonts/Poppins-SemiBold.ttf'
// import PoppinsThin from 'assets/fonts/Poppins-Thin.ttf'
// import PoppinsMedium from 'assets/fonts/Poppins-Medium.ttf'

const PDFGenerator = ({ data }) => {
    Font.register({
        family: 'Poppins',
        fonts: [
            {
                src: '/fonts/Poppins-Regular.ttf',
                fontWeight: 400
            },
            {
                src: '/fonts/Poppins-Thin.ttf',
                fontWeight: 100
            },
            {
                src: '/fonts/Poppins-Medium.ttf',
                fontWeight: 500
            },
            {
                src: '/fonts/Poppins-SemiBold.ttf',
                fontWeight: 600
            },
            {
                src: '/fonts/Poppins-Bold.ttf',
                fontWeight: 700
            },
            {
                src: '/fonts/Poppins-ExtraBold.ttf',
                fontWeight: 800
            }
        ]
    })

    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Poppins'
        },
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35
        },
        title: {
            textAlign: 'left',
            fontSize: 12,
            fontWeight: 400,
            fontFamily: 'Poppins',
            color: '#000087'
        },
        title2: {
            textAlign: 'left',
            fontSize: 11,
            fontWeight: 500,
            fontFamily: 'Poppins',
            color: '#333333'
        },
        header: {
            marginBottom: 10,
            color: '#9A979B',
            display: 'flex',
            flexDirection: 'row'
        },
        hr: {
            height: '1px',
            border: 0,
            borderTop: '1px solid rgba(231, 221, 236)',
            margin: '3px 0'
        },
        logo: {
            height: 28,
            width: 'auto'
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 8,
            bottom: 20,
            left: 30,
            right: 30,
            textAlign: 'center',
            color: '#9A979B',
            fontFamily: 'Poppins'
        },
        textHeading: {
            color: '#2304A3',
            fontSize: 9,
            fontWeight: 500,
            margin: '5px 0 4px 0',
            fontFamily: 'Poppins'
        },
        textNumberBold: {
            color: '#333333',
            fontSize: 11,
            fontWeight: 600,
            margin: '6px 0',
            fontFamily: 'Poppins'
        },
        textContent: {
            color: '#362B48',
            fontSize: 8,
            fontWeight: 500,
            margin: '4px 0',
            fontFamily: 'Poppins'
        },
        textContentSmall: {
            color: '#362B48',
            fontSize: 7,
            fontWeight: 400,
            margin: '3px 0',
            fontFamily: 'Poppins'
        },
        textcontentBold: {
            color: '#362B48',
            fontSize: 8,
            fontWeight: 500,
            margin: '4px 0',
            fontFamily: 'Poppins'
        },
        textMuted: {
            color: '#9CA3AF',
            fontSize: 8,
            fontWeight: 400,
            margin: '3px 0',
            fontFamily: 'Poppins'
        },
        textMutedSmall: {
            color: '#9CA3AF',
            fontSize: 7,
            fontWeight: 400,
            margin: '2px 0',
            fontFamily: 'Poppins'
        },
        asterisk: {
            height: 6,
            width: 6,
            margin: 0,
            padding: 0,
            display: 'flex',           
        }
    })

    const Header = () => (
        <View fixed>
            <View style={styles.header} fixed>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start'
                    }}
                >
                    <Image style={styles.logo} src={Logo} alt={'logo'} />
                </div>
            </View>
            <View>
                <Text style={styles.title}>Cost Comparison Report: </Text>
            </View>
        </View>
    )

    const Footer = () => (
        <View style={styles.pageNumber} fixed>
            <hr style={styles.hr} />
            <Text style={{}} render={({ pageNumber, totalPages }) => `(Page${pageNumber} of ${totalPages})`} />
        </View>
    )

    const Body = () => (
        <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: '50%', padding: '10px' }}>
                <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={styles.title2}>Current Flow </Text>
                    <Text style={styles.textNumberBold}>
                        {data['Chat Models']?.costType ? '$' + data['Chat Models']?.currCost?.toFixed(2) : 'NA'}{' '}
                        <Text style={styles.textMutedSmall}>
                            cost per {data['Chat Models']?.costType ? data['Chat Models']?.costType : ''}
                        </Text>{' '}
                    </Text>
                </View>
                <hr style={styles.hr} />
                {Object.keys(data)
                    .sort()
                    .map((da, index) => {
                        if (da === 'Chat Models') {
                            return (
                                <View key={index + '28734823784'}>
                                    <Text style={styles.textHeading}>{da} :</Text>
                                    <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <Text style={[styles.textContent, { width: '80%' }]}>{data[da].currComp}</Text>
                                        <View style={{ width: '20%', display: 'flex', alignItems: 'flex-end' }}>
                                            <Text style={[styles.textContent, { display: 'flex' }]}>
                                                {data[da].currCost ? '$' + data[da].currCost.toFixed(2) : 'NA'}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                                        <View style={{ width: '35%' }}>
                                            <Text style={styles.textMuted}>Model Name</Text>
                                        </View>
                                        <View style={{ width: '50%' }}>
                                            <Text style={styles.textContentSmall}>{data[da].currModel}</Text>
                                        </View>
                                        <View style={{ width: '15%', display: 'flex', alignItems: 'flex-end' }}>
                                            <Text style={styles.textContentSmall}>
                                                {data[da].currCost ? '$' + data[da].currCost.toFixed(2) : 'NA'}
                                            </Text>
                                        </View>
                                    </View>
                                    <hr style={styles.hr} />
                                </View>
                            )
                        } else {
                            return (
                                <View key={index + '28734823782'}>
                                    <Text style={styles.textHeading}>{da} :</Text>
                                    <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <Text style={[styles.textContent, { width: '80%' }]}>{data[da].currComp}</Text>
                                        <View style={{ width: '20%', display: 'flex', alignItems: 'flex-end' }}>
                                            <Text style={[styles.textContent, { display: 'flex' }]}>
                                                {data[da].currCost ? '$' + data[da].currCost.toFixed(2) : 'NA'}
                                            </Text>
                                        </View>
                                    </View>
                                    <hr style={styles.hr} />
                                </View>
                            )
                        }
                    })}
            </View>

            <View style={{ display: 'flex', width: '50%', padding: '10px' }}>
                <View style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                    <Text style={styles.title2}>Comparison Flow </Text>
                    <Text style={styles.textNumberBold}>
                        {data['Chat Models']?.costType ? '$' + data['Chat Models']?.compCost?.toFixed(2) : 'NA'}{' '}
                        <Text style={styles.textMutedSmall}>
                            cost per {data['Chat Models']?.costType ? data['Chat Models']?.costType : ''}
                        </Text>{' '}
                    </Text>
                </View>
                <hr style={styles.hr} />
                {Object.keys(data)
                    .sort()
                    .map((da, index) => {
                        if (da === 'Chat Models') {
                            return (
                                <View key={index + '287348237824'}>
                                    <Text style={styles.textHeading}>{da} :</Text>
                                    <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <View style={{ width: '80%', display: 'flex', justifyContent: 'flex-start' }}>
                                            <Text style={[styles.textContent, { display: 'flex' }]}>
                                                {data[da].compComp}
                                                {' '}
                                                {data[da].CompModel !== data[da].currModel ? (
                                                    <Image style={styles.asterisk} src={asterisk} alt={'asterisk'} />
                                                ) : null}
                                            </Text>
                                        </View>
                                        <View style={{ width: '20%', display: 'flex', alignItems: 'flex-end' }}>
                                            <Text style={[styles.textContent, { display: 'flex' }]}>
                                                {data[da].compCost ? '$' + data[da].compCost.toFixed(2) : 'NA'}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                                        <View style={{ width: '35%' }}>
                                            <Text style={styles.textMuted}>Model Name</Text>
                                        </View>
                                        <View style={{ width: '50%' }}>
                                            <Text style={styles.textContentSmall}>{data[da].CompModel}</Text>
                                        </View>
                                        <View style={{ width: '15%', display: 'flex', alignItems: 'flex-end' }}>
                                            <Text style={styles.textContentSmall}>
                                                {' '}
                                                {data[da].compCost ? '$' + data[da].compCost.toFixed(2) : 'NA'}
                                            </Text>
                                        </View>
                                    </View>
                                    <hr style={styles.hr} />
                                </View>
                            )
                        } else {
                            return (
                                <View key={index + '287343823782'}>
                                    <Text style={styles.textHeading}>{da} :</Text>
                                    <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <View style={{ width: '80%', display: 'flex', justifyContent: 'flex-start' }}>
                                            <Text style={[styles.textContent, { display: 'flex', position: 'relative' }]}>
                                                {data[da].compComp}
                                                {' '}
                                                {data[da].compComp !== data[da].currComp ? (
                                                    <Image style={styles.asterisk} src={asterisk} alt={'asterisk'} />
                                                ) : null}
                                            </Text>
                                        </View>

                                        <View style={{ width: '20%', display: 'flex', alignItems: 'flex-end' }}>
                                            <Text style={[styles.textContent, { display: 'flex' }]}>
                                                {data[da].compCost ? '$' + data[da].compCost.toFixed(2) : 'NA'}
                                            </Text>
                                        </View>
                                    </View>
                                    <hr style={styles.hr} />
                                </View>
                            )
                        }
                    })}
            </View>
        </View>
    )

    return (
        <Document>
            <Page size='A4' style={styles.body}>
                <Header />
                <Body />
                <Footer />
            </Page>
        </Document>
    )
}

PDFGenerator.propTypes = { data: PropTypes.object }

export default React.memo(PDFGenerator)
