// import React from 'react'

import { useNavigate } from 'react-router-dom'
// import Card from '@mui/material/Card'

// Add background image from your local repo

import '../../assets/css/login.css'

import microsoft from '../../assets/images/microsoft-logo.png'
// import LoginBG from '../assets/images/loginBG.svg'
import Button from '@mui/material/Button'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import logodark from '../../assets/images/logo-dark.svg'

const Index = () => {
    const { instance, inProgress } = useMsal()

    const isAuthenticated = useIsAuthenticated()

    const navigate = useNavigate()

    const handleRedirect = async () => {
        try {
            instance.loginRedirect({
                redirectStartPage: '/'
            })
            if (inProgress === InteractionStatus?.None && !isAuthenticated) {
                instance.loginRedirect({
                    redirectStartPage: '/'
                })
            } else {
                history.push(redirectAfterLoginPage)
            }
        } catch (err) {
            // console.log('error from login', err)
        }
    }

    return (
        <div style={{ height: '100%', width: '100%', padding: 0, margin: 0 }}>
            <div role='img' className='loginContainer' aria-label='sso-background'>
                <div className=''>
                    <div className='login-description'>
                        <span>
                            Unleashing the potential of Generative AI with our Gen AI powered Gen AI Playground.
                            <br />
                            <span className='login-description2'>
                                An agile way of LLM application development with no-code visual canvas.
                            </span>
                        </span>
                    </div>
                    <div className='loginCard'>
                        <div className='loginLogo'>
                            {/* <span>GENFLOW STUDIO</span> */}
                            <img src={logodark} alt='genai playground' />
                        </div>
                        <div style={{ padding: '1rem 0 2rem 0', marginTop: '4vh' }}>
                            <span style={{ fontSize: '12px' }}>Sign in using Microsoft credentials</span>
                            <div style={{ marginBottom: '3vh', marginTop: '1vh' }}>
                                <Button
                                    style={{
                                        backgroundColor: '#4A25E1',
                                        fontSize: '1rem',
                                        width: '100%',
                                        display: 'block',
                                        borderRadius: '8px',
                                        color: '#fff'
                                    }}
                                    type='submit'
                                    onClick={handleRedirect}
                                >
                                    <span style={{ marginRight: '1rem' }}>
                                        <span>SIGN IN</span>
                                    </span>
                                    <img src={microsoft} alt='microsoft' />
                                </Button>
                            </div>
                        </div>
                        <div style={{ fontSize: '12px' }}>
                            <p>© Copyright Tredence 2024</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
