import { createPortal } from 'react-dom'
import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, OutlinedInput, DialogTitle } from '@mui/material'
import TextField from '@mui/material/TextField'
import { StyledButton } from 'ui-component/button/StyledButton'

import { Dropdown } from 'ui-component/dropdown/Dropdown'
import InputLabel from '@mui/material/InputLabel'

const SaveChatflowDialog = ({ show, dialogProps, onCancel, onConfirm }) => {
    const portalElement = document.getElementById('portal')

    const [tag, setTag] = useState('custom')
    const [chatflowName, setChatflowName] = useState('')
    const [isReadyToSave, setIsReadyToSave] = useState(false)
    const [chatflowNameWarning, setChatflowNameWarning] = useState(null)
    const [chatflowDescription, setChatflowDescription] = useState('')
    const [trigger, setTrigger] = useState(false)

    const textAreaRef = useRef(null)

    useEffect(() => {
        if (chatflowName) {
            if (isValidInput(chatflowName)) {
                setChatflowNameWarning(null)
                setIsReadyToSave(true)
            } else {
                setIsReadyToSave(false)
                //set the error message
                setChatflowNameWarning('Oops! Special characters other than _ and - are not allowed here.')
            }
        } else {
            setIsReadyToSave(false)
        }
    }, [chatflowName])

    // useEffect(() => {
    //     if (textAreaRef?.current?.value == '') {
    //         textAreaRef.current.value = chatflowDescription
    //         setTrigger(false)
    //     }
    // }, [textAreaRef?.current?.value, trigger])

    const handleSelect = (v) => {
        setTrigger(true)
        // setChatflowDescription(textAreaRef?.current?.value)
        setTag(v)
    }

    function isValidInput(input) {
        const regex = /^[a-zA-Z0-9_-\s]+$/
        return regex.test(input)
    }

    const component = show ? (
        <Dialog
            open={show}
            fullWidth
            maxWidth='xs'
            onClose={onCancel}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle sx={{ fontSize: '1rem' }} id='alert-dialog-title'>
                {dialogProps.title}
            </DialogTitle>
            <DialogContent>
                <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                    Name *
                </InputLabel>
                <OutlinedInput
                    sx={{ mb: 1 }}
                    id='chatflow-name'
                    type='text'
                    fullWidth
                    placeholder='My New Chatflow'
                    value={chatflowName}
                    onChange={(e) => {
                        e.stopPropagation()
                        setChatflowName(e.target.value)
                    }}
                />
                {chatflowNameWarning != null ? <p style={{ color: 'red', fontSize: '10px' }}>{chatflowNameWarning}</p> : null}
                <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                    Description
                </InputLabel>
                <TextField
                    sx={{ mb: 1 }}
                    type='text'
                    fullWidth
                    id='outlined-multiline-flexible'
                    placeholder='Chatflow description'
                    multiline
                    rows={3}
                    onChange={(e) => {
                        e.stopPropagation()
                        setChatflowDescription(e.target.value)
                    }}
                />
                {/* <Textarea
                    // ref={textAreaRef}
                    onChange={(e) => setChatflowDescription(e.target.value)}
                    minRows={3}
                    placeholder='Chatflow description'
                /> */}

                <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                    Tag
                </InputLabel>
                <Dropdown
                    name='tags'
                    value={tag}
                    options={[
                        { name: 'text', label: 'Text' },
                        { name: 'data', label: 'Data' },
                        { name: 'custom', label: 'Custom' },
                        { name: 'tredence', label: 'Tredence' }
                    ]}
                    onSelect={(v) => handleSelect(v)}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onCancel}>{dialogProps.cancelButtonName}</Button>
                <StyledButton
                    disabled={!isReadyToSave}
                    variant='contained'
                    onClick={(event) => {
                        event.stopPropagation()
                        onConfirm(chatflowName, chatflowDescription, false, tag)
                        // console.log('desc:', chatflowDescription)
                    }}
                >
                    {dialogProps.confirmButtonName}
                </StyledButton>
            </DialogActions>
        </Dialog>
    ) : null

    return createPortal(component, portalElement)
}

SaveChatflowDialog.propTypes = {
    show: PropTypes.bool,
    dialogProps: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
}

export default SaveChatflowDialog
