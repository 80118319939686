import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Avatar, Box, ButtonBase, Typography, Stack, TextField } from '@mui/material'
import LogoSection from 'layout/MainLayout/LogoSection'

// icons
import {
    IconSettings,
    IconChevronLeft,
    IconDeviceFloppy,
    IconPencil,
    IconCheck,
    IconX,
    IconCode,
    IconSquareRoundedCheck,
    IconHammer
} from '@tabler/icons'

// project imports
import Settings from 'views/settings'
import SaveChatflowDialog from 'ui-component/dialog/SaveChatflowDialog'
import APICodeDialog from 'views/chatflows/APICodeDialog'
import AnalyseFlowDialog from 'ui-component/dialog/AnalyseFlowDialog'
import ViewMessagesDialog from 'ui-component/dialog/ViewMessagesDialog'
import StarterPromptsDialog from 'ui-component/dialog/StarterPromptsDialog'

// API
import chatflowsApi from 'api/chatflows'

// Hooks
import useApi from 'hooks/useApi'

// utils
import { generateExportFlowData } from 'utils/genericHelper'
import { uiBaseURL } from 'store/constant'
import { SET_CHATFLOW } from 'store/actions'
import { Margin } from '@mui/icons-material'

// ==============================|| CANVAS HEADER ||============================== //

const CanvasHeader = ({ chatflow }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const flowNameRef = useRef()
    const settingsRef = useRef()

    const [isEditingFlowName, setEditingFlowName] = useState(null)
    const [flowName, setFlowName] = useState('')
    const [nodes, setNodes] = useState([])
    const [isSettingsOpen, setSettingsOpen] = useState(false)
    const [flowDialogOpen, setFlowDialogOpen] = useState(false)
    const [apiDialogOpen, setAPIDialogOpen] = useState(false)
    const [apiDialogProps, setAPIDialogProps] = useState({})
    const [analyseDialogOpen, setAnalyseDialogOpen] = useState(false)
    const [analyseDialogProps, setAnalyseDialogProps] = useState({})
    const [conversationStartersDialogOpen, setConversationStartersDialogOpen] = useState(false)
    const [conversationStartersDialogProps, setConversationStartersDialogProps] = useState({})
    const [viewMessagesDialogOpen, setViewMessagesDialogOpen] = useState(false)
    const [viewMessagesDialogProps, setViewMessagesDialogProps] = useState({})

    const updateChatflowApi = useApi(chatflowsApi.updateChatflow)
    const canvas = useSelector((state) => state.canvas)

    useEffect(() => {
        if (chatflow) {
            setFlowName(chatflow.name)
            // setNodes(JSON.parse(chatflow?.flowData ? chatflow?.flowData : '{}')?.nodes)
        }
    }, [chatflow])

    return (
        <>
            <Box>
                <ButtonBase title='Back' sx={{ borderRadius: '50%' }}>
                    <Avatar
                        variant='rounded'
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        color='inherit'
                        onClick={() => navigate(-1)}
                    >
                        <IconChevronLeft stroke={1.5} size='1.3rem' />
                    </Avatar>
                </ButtonBase>
            </Box>
            <div style={{ display: 'flex', width: '100%' }}>
                {/* <Box component='span' sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}> */}
                <div>
                    <LogoSection />
                </div>
                {/* </Box> */}
                {/* <Box sx={{ flexGrow: 1 ,marginLeft:'10rem'}}> */}
                {!isEditingFlowName && (
                    <div style={{ marginLeft: '16rem' }}>
                        <Typography
                            sx={{
                                fontSize: '1.5rem',
                                fontWeight: 600,
                                ml: 2
                            }}
                        >
                            {canvas.isDirty && <strong style={{ color: theme.palette.orange.main }}>*</strong>} {flowName}
                        </Typography>
                    </div>
                )}
                {/* </Box> */}
            </div>
        </>
    )
}

CanvasHeader.propTypes = {
    chatflow: PropTypes.object
}

export default CanvasHeader
