/* eslint-disable */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import './comparePage.css'
import { Toolbar, Box, AppBar, Button } from '@mui/material'
import { Spin, Card } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import asterisk from 'assets/images/asterisk.svg'
import download from 'assets/images/download.svg'
import star from 'assets/images/star.svg'
import infoBlue from 'assets/images/infoBlue.svg'
import orangeC from 'assets/images/orangeC.svg'
import { useTheme } from '@mui/material/styles'
import CanvasHeader from '../../views/canvas/EditCostHeader'
import { useDispatch, useSelector } from 'react-redux'
import PdfGenerator from './PdfGenerator'
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'

const ComparePage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [chatflow, setChatflow] = useState(null)
    const [pdfData, setPdfData] = useState(null)
    const [selectedTennure, setSelectedTennure] = useState('Monthly')
    const theme = useTheme()

    const compareData = useSelector((state) => state.compare.compareData)
    const flowData = useSelector((state) => state.compare.flowData)
    const costData = useSelector((state) => state.compare.costData)
    const costingParams = useSelector((state) => state.compare.costingParams)

    // const getFlowConfig = useApi(chatflowApi.getFlowconfig)

    const [modelCostData, setModelCostData] = useState(null)

    useEffect(() => {
        let dataObj = JSON.parse(localStorage.getItem('dataObj'))
        setChatflow(dataObj.chatFlow)
        setSelectedTennure(dataObj.selectedTennure)
        // console.log(dataObj.chatFlow.id)
        // console.log('data obj from cmp', dataObj?.chatFlow?.name)
    }, [localStorage.getItem('dataObj')])

    // useEffect(() => {
    //     console.log(compareData, 'comapare data from cp')
    //     console.log(flowData, 'flow data from the cp')
    //     console.log(costData, 'cost data from the cp')
    // }, [compareData, flowData, costData])

    useEffect(() => {
        if (compareData && flowData && costData) {
            console.log('data 101', costingParams)
            priceCalculator('monthly')
        } else {
            const url = location.pathname
            const strippedUrl = url.replace('/compareCost', '')
            navigate(strippedUrl)
        }
    }, [compareData, flowData, costData, costingParams])

    const priceCalculator = (costType) => {
        // -------------------------constants for azure chat model cost calculation---------------------------
        const UsersInDevelopment = costingParams?.['Azure ChatOpenAI']?.['Users in Development']
            ? costingParams?.['Azure ChatOpenAI']?.['Users in Development']
            : 2

        const EstimatedRequestsPerAPICallsInDevelopment = costingParams?.['Azure ChatOpenAI']?.[
            'Estimated requests per API calls in Development'
        ]
            ? costingParams?.['Azure ChatOpenAI']?.['Estimated requests per API calls in Development']
            : 3

        const UsersInProduction = costingParams?.['Azure ChatOpenAI']?.['Users in Production']
            ? costingParams?.['Azure ChatOpenAI']?.['Users in Production']
            : 2

        const EstimatedRequestsPerAPICallsInProduction = costingParams?.['Azure ChatOpenAI']?.[
            'Estimated requests per API calls in Production'
        ]
            ? costingParams?.['Azure ChatOpenAI']?.['Estimated requests per API calls in Production']
            : 2

        const MessageHistory = costingParams?.['Azure ChatOpenAI']?.['Message History']
            ? costingParams?.['Azure ChatOpenAI']?.['Message History']
            : 30

        const UserQuery = costingParams?.['Azure ChatOpenAI']?.['User Query'] ? costingParams?.['Azure ChatOpenAI']?.['User Query'] : 10

        const FewShotExamples = costingParams?.['Azure ChatOpenAI']?.['Few-Shot Examples']
            ? costingParams?.['Azure ChatOpenAI']?.['Few-Shot Examples']
            : 10

        const SystemMessage = costingParams?.['Azure ChatOpenAI']?.['System Message']
            ? costingParams?.['Azure ChatOpenAI']?.['System Message']
            : 10

        const MaxResponse = costingParams?.['Azure ChatOpenAI']?.['Max Response']
            ? costingParams?.['Azure ChatOpenAI']?.['Max Response']
            : 40

        const PeakCallsPerMinute =
            UsersInDevelopment * EstimatedRequestsPerAPICallsInDevelopment + UsersInProduction * EstimatedRequestsPerAPICallsInProduction
        const TokensinPromptcall = MessageHistory + UserQuery + FewShotExamples + SystemMessage
        const TokensInModelResponse = MaxResponse
        const prompt = PeakCallsPerMinute * TokensinPromptcall
        const generated = PeakCallsPerMinute * TokensInModelResponse
        const tokensPerMinute = PeakCallsPerMinute * (TokensinPromptcall + TokensInModelResponse)

        //this is taken on average. 1000 tokens per minute.
        // const tokenPerRateLimitPerThousand = 1
        let flowDataProvider = null
        let flowDataModel = null
        let compareProvider = null
        let compareModel = null
        let flowDataCost = null
        let compareDataCost = null

        //final cost
        let flowDataTotalCost = null
        let compareDataTotalCost = null

        //iterator optimization counter.
        let count = 0

        //flowData cost calculation.
        if (flowData['Chat Models']) {
            flowDataProvider = flowData['Chat Models']?.[0]?.label
            flowDataModel = flowData['Chat Models']?.[0]?.nodes?.[0]?.data?.inputs?.modelName
        }

        if (compareData['Chat Models']) {
            compareProvider = compareData['Chat Models']
            compareModel = compareData['Model Name'] ? compareData['Model Name'] : ''
        }

        for (let i = 0; i < costData.length; i++) {
            if (costData[i].provider === flowDataProvider && costData[i].model === flowDataModel) {
                flowDataCost = {
                    inputCost: Number(costData[i]?.pricings[0]?.input_price),
                    outputCost: Number(costData[i]?.pricings[0]?.output_price)
                }
                count++
            }
            if (costData[i].provider === compareProvider && costData[i].model === compareModel) {
                compareDataCost = {
                    inputCost: Number(costData[i]?.pricings[0]?.input_price),
                    outputCost: Number(costData[i]?.pricings[0]?.output_price)
                }
                count++
            } else if (!compareModel) {
                compareDataCost = null
            }
            if (count === 2) {
                break
            }
        }

        //flow data calc
        if (flowDataCost && flowDataProvider === 'Azure ChatOpenAI') {
            let flowInputCost = flowDataCost['inputCost']
            let flowOutputCost = flowDataCost['outputCost']

            // const inputCost = (Number(flowInputCost) * prompt) / 1000
            const inputCost = (Number(flowInputCost) * tokensPerMinute) / 1000
            const outputCost = (Number(flowOutputCost) * tokensPerMinute) / 1000
            const perMinuteCost = inputCost + outputCost
            const perHourCost = perMinuteCost * 60
            const perDayCost = perHourCost * 24
            const perMonthCost = perDayCost * 30
            const perYearCost = perDayCost * 365

            if (costType === 'Daily') {
                flowDataTotalCost = perDayCost
            } else if (costType === 'Monthly') {
                flowDataTotalCost = perMonthCost
            } else if (costType === 'Annual') {
                flowDataTotalCost = perYearCost
            } else {
                flowDataTotalCost = perMonthCost
            }
        } else {
            flowDataTotalCost = null
        }

        //compare data calc
        if (compareDataCost && compareProvider === 'Azure ChatOpenAI') {
            let compareInputCost = compareDataCost['inputCost']
            let compareOutputCost = compareDataCost['outputCost']

            // const inputCost = (Number(compareInputCost) * prompt) / 1000
            const inputCost = (Number(compareInputCost) * tokensPerMinute) / 1000
            const outputCost = (Number(compareOutputCost) * tokensPerMinute) / 1000
            const perMinuteCost = inputCost + outputCost
            const perDayCost = perMinuteCost * 60 * 24
            const perMonthCost = perDayCost * 30
            const perYearCost = perDayCost * 365

            if (costType === 'daily') {
                compareDataTotalCost = perDayCost
            } else if (costType === 'monthly') {
                compareDataTotalCost = perMonthCost
            } else if (costType === 'annually') {
                compareDataTotalCost = perYearCost
            } else {
                compareDataTotalCost = perMonthCost
            }
        } else {
            compareDataTotalCost = null
        }

        setModelCostData({
            flowDataTotalCost: flowDataTotalCost,
            compareDataTotalCost: compareDataTotalCost
        })
    }

    const onChangetennure = (data) => {
        setSelectedTennure(data)
        priceCalculator(data)
    }

    useEffect(() => {
        if (flowData && modelCostData && compareData) {
            let formattedObj = {}
            const keys = Object.keys(flowData)

            for (let i = 0; i < keys.length; i++) {
                let tempObj = {}
                let category = keys[i]
                tempObj['compComp'] = compareData[category]
                if (category === 'Chat Models') {
                    tempObj['currComp'] = flowData[keys[i]][0].label
                    tempObj['currModel'] = flowData[keys[i]][0]?.nodes?.[0]?.data?.inputs?.modelName
                    tempObj['CompModel'] = compareData['Model Name']
                    tempObj['currCost'] = modelCostData.flowDataTotalCost
                    tempObj['compCost'] = modelCostData.compareDataTotalCost
                    tempObj['costType'] = selectedTennure === 'Annul' ? 'year' : selectedTennure === 'Monthly' ? 'month' : 'day'
                } else {
                    tempObj['currComp'] = flowData[keys[i]][0].label
                    tempObj['currCost'] = null
                    tempObj['compCost'] = null
                }
                formattedObj[category] = tempObj
            }
            setPdfData(formattedObj)
        }
    }, [flowData, modelCostData, compareData])

    return (
        <div>
            {compareData && flowData && costData && modelCostData ? (
                <div>
                    <AppBar
                        enableColorOnDark
                        position='fixed'
                        color='inherit'
                        elevation={1}
                        sx={{
                            bgcolor: theme.palette.background.default
                        }}
                    >
                        <Toolbar>
                            <CanvasHeader chatflow={chatflow} />
                        </Toolbar>
                    </AppBar>

                    <div className={'rootContainerCompare'} style={{ marginTop: '5rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 24px' }}>
                            <span className={'title1'}>Cost Comparison </span>
                            <div className='row' style={{ width: '40%', padding: '0px', maxWidth: '400px' }}>
                                <div
                                    className={selectedTennure == 'Annual' ? 'selectedTab' : 'tab'}
                                    style={{ padding: '4px 0', cursor: 'pointer' }}
                                    onClick={() => {
                                        onChangetennure('Annual')
                                    }}
                                >
                                    Annual
                                </div>
                                <div
                                    className={selectedTennure == 'Monthly' ? 'selectedTab' : 'tab'}
                                    style={{ padding: '4px 0', cursor: 'pointer' }}
                                    onClick={() => {
                                        onChangetennure('Monthly')
                                    }}
                                >
                                    Monthly
                                </div>
                                <div
                                    className={selectedTennure == 'Daily' ? 'selectedTab' : 'tab'}
                                    style={{ padding: '4px 0', cursor: 'pointer' }}
                                    onClick={() => {
                                        onChangetennure('Daily')
                                    }}
                                >
                                    Daily
                                </div>
                            </div>

                            <div style={{ minWidth: '45px', display: 'flex', justifyContent: 'end' }}>
                                {pdfData ? (
                                    <PDFDownloadLink
                                        document={<PdfGenerator data={pdfData} />}
                                        fileName={
                                            chatflow?.name
                                                ? 'Cost Comparison Report - ' + chatflow?.name + '.pdf'
                                                : 'Cost Comparison Report.pdf'
                                        }
                                    >
                                        {({ blob, url, loading, error }) =>
                                            loading ? (
                                                <Spin size='small' />
                                            ) : (
                                                <div style={{ isplay: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img style={{ height: '20px', width: '20px' }} alt='download button' src={download} />
                                                </div>
                                            )
                                        }
                                    </PDFDownloadLink>
                                ) : (
                                    // <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                                    <> </>
                                )}
                            </div>
                        </div>
                        <Box sx={{ flexGrow: 1 }} style={{ padding: '12px 24px', height: ' 100%', overflowY: 'auto' }}>
                            <table style={{ width: '100%', backgroundColor: '#f1faff', borderCollapse: 'collapse' }}>
                                <tr style={{ width: '100%' }}>
                                    <td style={{ width: '20%', backgroundColor: '#fefefe' }} className='compTextCont'>
                                        <span className='compText'>Components</span>
                                    </td>
                                    <td style={{ width: '40%', padding: '0', margin: '0 3px 0 0' }}>
                                        <div className='greenColStyle1'>
                                            <Card
                                                style={{
                                                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
                                                    margin: '4px 8px',
                                                    padding: '4px 0'
                                                }}
                                            >
                                                <div className={'flexCol'}>
                                                    <span className={'fontStyle1'}>Current Flow</span>
                                                    <div className='flexRow'>
                                                        <span className='fontNumber'>
                                                            {modelCostData ? '$ ' + modelCostData?.flowDataTotalCost?.toFixed(2) : 'NA'}
                                                        </span>
                                                        <span className='fontMutedText'>
                                                            &nbsp;&nbsp;cost per{' '}
                                                            {selectedTennure === 'Annual'
                                                                ? 'year'
                                                                : selectedTennure === 'Monthly'
                                                                ? 'month'
                                                                : 'day'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </td>
                                    <td style={{ width: '40%', padding: '0', margin: '0 0 0 3px' }}>
                                        <div className='greenColStyle2'>
                                            <Card
                                                style={{
                                                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
                                                    margin: '4px 8px',
                                                    padding: '4px 0'
                                                }}
                                            >
                                                <div className='flexRow'>
                                                    <span className={'fontStyle1'}>Comparison Flow</span>
                                                    {/* <span className='badgeGreen'>
                                                <img style={{ width: '0.6rem', height: '0.6rem' }} alt='' src={star} />
                                                Recommended
                                            </span> */}
                                                </div>

                                                <div className='flexRow'>
                                                    <span className='fontNumber'>
                                                        {modelCostData ? '$ ' + modelCostData?.compareDataTotalCost?.toFixed(2) : 'NA'}
                                                    </span>
                                                    <span className='fontMutedText'>
                                                        &nbsp;&nbsp;cost per{' '}
                                                        {selectedTennure === 'Annual'
                                                            ? 'year'
                                                            : selectedTennure === 'Monthly'
                                                            ? 'month'
                                                            : 'day'}
                                                    </span>
                                                </div>
                                            </Card>
                                        </div>
                                    </td>
                                </tr>

                                {typeof flowData === 'object' &&
                                    Object.keys(flowData)
                                        .sort()
                                        .map((item, index) => {
                                            return (
                                                <tr style={{ width: '100%', borderBottom: '1px solid #C6C7C8' }} key={'cp12234' + index}>
                                                    <td style={{ width: '20%', backgroundColor: '#fefefe' }} className='anchorTop'>
                                                        <span className='fontStyle2 flexRowLeft '>
                                                            {item}
                                                            <img
                                                                style={{ width: '16px', height: '16px', margin: '0 0 0 4px' }}
                                                                alt=''
                                                                src={infoBlue}
                                                            />
                                                        </span>
                                                    </td>
                                                    <td style={{ width: '40%', padding: '0', margin: '0' }}>
                                                        <div className='greenColStyle1'>
                                                            <div className=''>
                                                                <table style={{ width: '100%' }}>
                                                                    <tr style={{ width: '100%' }}>
                                                                        <td
                                                                            style={{
                                                                                width: '75%',
                                                                                padding: '6px 0',
                                                                                margin: '0',
                                                                                display: 'flex'
                                                                            }}
                                                                        >
                                                                            <span className='fontStyle3 textPadding1'>
                                                                                {flowData[item]?.[0]?.label}
                                                                            </span>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '25%',
                                                                                padding: '6px 0',
                                                                                margin: '0',
                                                                                textAlign: 'end'
                                                                            }}
                                                                        >
                                                                            <span className='fontStyle3 textPadding1'>
                                                                                {item === 'Chat Models' && modelCostData
                                                                                    ? '$ ' + modelCostData?.flowDataTotalCost?.toFixed(2)
                                                                                    : 'NA'}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                {item === 'Chat Models' ? (
                                                                    <table style={{ width: '100%' }}>
                                                                        <tr style={{ width: '100%', height: 'auto' }}>
                                                                            <td style={{ width: '27%', padding: '4px 0', margin: '0' }}>
                                                                                <span className='fontStyle4 textPadding2'>Model Name</span>
                                                                            </td>
                                                                            <td style={{ width: '51%', padding: '4px 0', margin: '0' }}>
                                                                                <span className='fontStyle5 textPadding2'>
                                                                                    {flowData['Chat Models']?.[0]?.nodes?.[0]?.data?.inputs
                                                                                        .modelName
                                                                                        ? flowData['Chat Models']?.[0]?.nodes?.[0]?.data
                                                                                              ?.inputs.modelName
                                                                                        : 'NA'}
                                                                                </span>
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    width: '22%',
                                                                                    padding: '4px 0',
                                                                                    margin: '0',
                                                                                    textAlign: 'end'
                                                                                }}
                                                                            >
                                                                                <span className='fontStyle5 textPadding2'>
                                                                                    {item === 'Chat Models' && modelCostData
                                                                                        ? '$ ' +
                                                                                          modelCostData?.flowDataTotalCost?.toFixed(2)
                                                                                        : 'NA'}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '40%', padding: '0', margin: '0' }}>
                                                        <div className='greenColStyle2'>
                                                            <div className=''>
                                                                <table style={{ width: '100%' }}>
                                                                    <tr style={{ width: '100%' }}>
                                                                        <td
                                                                            style={{
                                                                                width: '75%',
                                                                                padding: '6px 0',
                                                                                margin: '0',
                                                                                display: 'flex'
                                                                            }}
                                                                        >
                                                                            <span className='fontStyle3 textPadding1'>
                                                                                {compareData[item] ? compareData[item] : 'NA'}
                                                                            </span>
                                                                            {compareData[item] !== flowData[item]?.[0]?.label ? (
                                                                                <img
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        width: '10px',
                                                                                        height: '10px'
                                                                                    }}
                                                                                    alt=''
                                                                                    src={asterisk}
                                                                                />
                                                                            ) : null}
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '25%',
                                                                                padding: '6px 0',
                                                                                margin: '0',
                                                                                textAlign: 'end'
                                                                            }}
                                                                        >
                                                                            <span className='fontStyle3 textPadding1'>
                                                                                {item === 'Chat Models' && modelCostData
                                                                                    ? '$ ' + modelCostData?.compareDataTotalCost?.toFixed(2)
                                                                                    : 'NA'}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                {item === 'Chat Models' ? (
                                                                    <table style={{ width: '100%' }}>
                                                                        <tr style={{ width: '100%', height: 'auto' }}>
                                                                            <td style={{ width: '27%', padding: '4px 0', margin: '0' }}>
                                                                                <span className='fontStyle4 textPadding2'>Model Name</span>
                                                                            </td>
                                                                            <td style={{ width: '51%', padding: '4px 0', margin: '0' }}>
                                                                                <span className='fontStyle5 textPadding2 flexRowLeft'>
                                                                                    {compareData['Model Name']}

                                                                                    {compareData['Model Name'] !==
                                                                                    flowData['Chat Models']?.[0]?.nodes?.[0]?.data?.inputs
                                                                                        .modelName ? (
                                                                                        <img
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                alignSelf: 'start',
                                                                                                width: '10px',
                                                                                                height: '10px'
                                                                                            }}
                                                                                            alt=''
                                                                                            src={asterisk}
                                                                                        />
                                                                                    ) : null}
                                                                                </span>
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    width: '22%',
                                                                                    padding: '4px 0',
                                                                                    margin: '0',
                                                                                    textAlign: 'end'
                                                                                }}
                                                                            >
                                                                                <span className='fontStyle5 textPadding2'>
                                                                                    {item === 'Chat Models' && modelCostData
                                                                                        ? '$ ' +
                                                                                          modelCostData?.compareDataTotalCost?.toFixed(2)
                                                                                        : 'NA'}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                            </table>
                        </Box>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

// ComparePage.propTypes = {
//     data: PropTypes.object
// }

export default ComparePage
