import CryptoJS from 'crypto-js';

const encrypt = (text, password) => {
    return CryptoJS.AES.encrypt(text, password).toString();
};

const decrypt = (encryptedText, password) => {
    const bytes = CryptoJS.AES.decrypt(encryptedText, password);
    return bytes.toString(CryptoJS.enc.Utf8);
};

export { encrypt, decrypt };
