import { lazy } from 'react'

// project imports
import Loadable from 'ui-component/loading/Loadable'
import MinimalLayout from 'layout/MinimalLayout'
import ComparePage from 'ui-component/comparePage/comparePage'

// import EditCost from 'ui-component/editCost/editCost'

// canvas routing
const Canvas = Loadable(lazy(() => import('views/canvas')))
const EditCost = Loadable(lazy(() => import('views/editCost')))
const MarketplaceCanvas = Loadable(lazy(() => import('views/marketplaces/MarketplaceCanvas')))

// ==============================|| CANVAS ROUTING ||============================== //

const CanvasRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/canvas',
            element: <Canvas />
        },
        {
            path: '/canvas/:id',
            element: <Canvas />
        },
        {
            path: '/canvas/:id/editCost',
            element: <EditCost />
        },
        {
            path: '/marketplace/:id',
            element: <MarketplaceCanvas />
        },
        {
            path: '/canvas/:id/compareCost',
            element: <ComparePage />
        }
    ]
}

export default CanvasRoutes
