import axios from 'axios'
import { baseURL } from 'store/constant'
import { decrypt } from 'utils/password'

const apiClient = axios.create({
    baseURL: `${baseURL}/api/v1`,
    headers: {
        'Content-type': 'application/json'
    }
})

apiClient.interceptors.request.use(function (config) {
//     try{
//     const username = localStorage.getItem('username')
//     console.log(username)
//     const password = decrypt(localStorage.getItem('password'),username)
//    console.log(username,password)
//     }catch(e){
//         console.log(e,'%%%%%')
//     }

//         config.auth = {
//             username,
//             password
//         }
    

    return config
})

export default apiClient
