// import logo from 'assets/images/flowise_logo.png'
// import logoDark from 'assets/images/flowise_logo_dark.png'

import logo from 'assets/images/logo-light.svg'
import logoDark from 'assets/images/logo-dark.svg'

import { useSelector } from 'react-redux'

// ==============================|| LOGO ||============================== //

const Logo = () => {
    const customization = useSelector((state) => state.customization)

    return (
        <div style={{ alignItems: 'center', flexDirection: 'row' }}>
            <div
                style={
                    customization.isDarkMode
                        ? {
                              display: 'flex',
                              alignItems: 'center',
                              padding: '3px',
                              marginRight: '2px'
                          }
                        : {
                              display: 'flex',
                              alignItems: 'center',
                              padding: '3px',
                              marginRight: '2px'
                          }
                }
            >
                <img
                    style={{ objectFit: 'contain', height: '30px', width: 'auto' }}
                    src={customization.isDarkMode ? logoDark : logo}
                    alt=''
                />
                {/* <span
                    style={
                        customization.isDarkMode
                            ? { fontSize: '1.7rem', fontFamily: 'Agency FB', color: '#fff', marginLeft: '3px' }
                            : { fontSize: '1.7rem', fontFamily: 'Agency FB', color: '#DD1836', marginLeft: '3px' }
                    }
                >
                    {' '}
                    GENFLOW STUDIO{' '}
                </span> */}
            </div>
        </div>
    )
}

export default Logo
